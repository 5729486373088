import React from 'react';
import './choops.scss';

const ChoopWindow = () => (
  <>
    <div className="choopmojis">🐓🐓🐓🐓🐓🐓🐓</div>

    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '2rem',
      }}
    >
      <iframe
        title="chicken video frame"
        className="choop_video"
        frameBorder="0"
        src="//video.nest.com/embedded/live/XhrkSK7tWk?autoplay=1"
        allowFullScreen
      />
    </div>
  </>
);

export default () => (
  <>
    {/* <Helmet title="kami boers" /> */}
    <ChoopWindow />
  </>
);
